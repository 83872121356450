<script setup lang="ts">
import type { SbFooterItemStoryblok } from '@/types'

interface Props {
  item: SbFooterItemStoryblok
}

const props = defineProps<Props>()

const hasPill = computed(() => !!props.item.pill_content)
</script>

<template>
  <BaseLink :address="item.link" :target="item.link?.target" class="footer-item">
    <span class="footer-item__name">{{ item.name }}</span>
    <span v-if="hasPill" class="footer-item__pill">
      {{ item.pill_content }}
    </span>
  </BaseLink>
</template>

<style lang="scss" scoped>
.footer-item {
  @include paragraph-s;

  display: inline-flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  color: var(--color-text);
  padding: 4px 0;
  border-radius: var(--border-radius-base);

  @media (min-width: $breakpoint-lg) {
    @include paragraph-xs;
  }

  &:focus {
    outline: 1px solid var(--color-link);
  }

  &:hover {
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover .footer-item__name {
      color: var(--color-link);
    }
  }

  &__pill {
    font-size: 12px;
    padding: 2px 12px;
    line-height: 21px;
    font-weight: 400;
    background: var(--gray-300);
    border-radius: 16px;
    letter-spacing: 0.5px;

    @media (min-width: $breakpoint-lg) {
      padding: 2px 8px;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
</style>
